import * as React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../../components/layout";
import Helmet from "react-helmet";
import config from "../../utils/siteconfig";
import Container from "../../components/container";
import Navbar from "../../components/small-navbar";
import PortfolioAllCard from "../../components/pages/portfolioAllpageCard";
import H1Title from "../../components/shared/h1-title";

const AllProjects = ({ data }) => {
  const projects = data.projectsAll.edges;

  return (
    <Layout>
      <Helmet>
        <title>{`${`All Projects`} - ${config.siteTitle}`}</title>
      </Helmet>
      <Container>
        <Navbar color={`black`} />
        <div className="grid gap-8 md:gap-10 lg:gap-6 md:grid-cols-12">
          <div className="col-span-12 ">
            <H1Title>All Projects</H1Title>
            <div className="grid space-y-16 lg:space-y-0 gap-8 md:gap-10 lg:gap-6 md:grid-cols-12">
              {projects.map(({ node }) => {
                return (
                  <PortfolioAllCard
                    id={node.id}
                    projectMainImage={node.mainImage}
                    alt={node.mainImage.title}
                    title={node.title}
                    link={`/projects/${node.slug}`}
                    description={node.description}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default AllProjects;

export const query = graphql`
  query {
    projectsAll: allContentfulWork(sort: { order: DESC, fields: createdAt }) {
      edges {
        node {
          title
          slug
          contentType
          mainImage {
            gatsbyImageData
          }
          description {
            description
          }
        }
      }
    }
  }
`;
