import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import H3Title from "../shared/h3-title";

const PortfolioAllCard = ({ id, ...props }) => {
  return (
    <div className="col-span-12 md:col-span-12 lg:col-span-6" key={props.id}>
      <div className="grid gap-8 md:grid-cols-12">
        <div className="md:col-span-4">
          <div className="relative h-48">
            <Link to={props.link} className="cursor-pointer">
              <GatsbyImage
                image={props.projectMainImage.gatsbyImageData}
                className="absolute top-0 left-0 object-contain w-full h-full duration-500 ease-in-out transform shadow-xl hover:scale-105 motion-reduce:transition-none motion-reduce:transform-none filter dark:brightness-95"
                alt={props.alt}
              />
            </Link>
          </div>
        </div>
        <div className="md:col-span-8 font-gotham">
          <H3Title>{props.title}</H3Title>
          {props.description == null ? (
            ""
          ) : (
            <p className="font-whitney">{props.description.description}</p>
          )}

          <Link to={props.link} className="font-bold font-whitney">
            View
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PortfolioAllCard;
