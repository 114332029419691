import React from "react";

const H1Title = ({ id, ...props }) => {
  return (
    <div className="mb-12">
      {props.center == null ? (
        <h1
          className="z-10 text-4xl lg:text-6xl font-bold leading-tight tracking-tight text-black font-whitney text-gnNavy-400 dark:text-gray-100"
          id={props.uniqid}
        >
          {props.children}
        </h1>
      ) : (
        <h1
          className="z-10 text-4xl lg:text-6xl font-bold leading-tight tracking-tight text-center text-black font-whitney text-gnNavy-400 dark:text-gray-100"
          id={props.uniqid}
        >
          {props.children}
        </h1>
      )}
    </div>
  );
};

export default H1Title;
