import React from "react";

const H2Title = ({ id, ...props }) => {
  return (
    <div className="py-0">
      {props.center == null ? (
        <h3
          className="pb-0 mb-0 text-xl font-black font-whitney text-gnNavy-400 dark:text-white"
          id={props.uniqid}
        >
          {props.children}
        </h3>
      ) : (
        <h3
          className="pb-0 mb-0 text-xl font-black text-center font-whitney text-gnNavy-400 dark:text-white"
          id={props.uniqid}
        >
          {props.children}
        </h3>
      )}
    </div>
  );
};

export default H2Title;
